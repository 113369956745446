<script setup lang="ts">
import FitEngineering from '@/components/lbm/fit/Engineering.vue'
import FitProduct from '@/components/lbm/fit/Product.vue'
import FitProgram from '@/components/lbm/fit/Program.vue'
import FitProjectTypes from '@/components/lbm/fit/ProjectTypes.vue'

import { animate } from 'motion'

const emit = defineEmits(['close'])

const wiper: Ref<HTMLElement | null> = ref(null)

const slideIndex: Ref<number> = ref(0)
const slideIndexLoaded: Ref<number> = ref(0)

onMounted(() => {
  document.body.style.overflow = 'hidden'
  document.body.style.height = '100%'
  slideIndex.value = 0
  setTimeout(() => {
    slideIndexLoaded.value = 0
  }, 1000)
})

onUnmounted(() => {
  document.body.style.overflow = ''
  document.body.style.height = ''
})

const slides = [
  {
    name: 'Introduction',
    colors: {
      wiperColors: [
        'bg-indigo-500',
        'bg-indigo-400',
        'bg-indigo-300',
        'bg-indigo-200',
      ],
      background: {
        from: 'from-indigo-500/50',
        to: 'to-indigo-800/80',
      },
      text: 'text-indigo-200',
      button: {
        bg: 'bg-indigo-500',
        hover: 'hover:bg-indigo-400',
      },
    },
    subtitle: 'The question is simple:',
    title: 'Are we a great fit for your project?',
    copy: `This is almost certainly the reason you are here. The answer is one that will have to be finalized after we are able to have a quick introduction to your organization and it's goals. That said, let us illustrate a clear picture of what you can expect from our firm.`,
    component: null,
  },
  {
    name: 'ProjectTypes',
    colors: {
      wiperColors: [
        'bg-cyan-500',
        'bg-cyan-400',
        'bg-cyan-300',
        'bg-cyan-200',
      ],
      background: {
        from: 'from-cyan-500/50',
        to: 'to-cyan-800/80',
      },
      text: 'text-cyan-200',
      button: {
        bg: 'bg-cyan-500',
        hover: 'hover:bg-cyan-400',
      },
    },
    subtitle: 'What do we make?',
    title: 'Tailored solutions for the browser',
    copy: `All that means is that we write custom solutions for organizations that run in the
              web browser. Those solutions might appear as marketing sites like this one or they might appear more
              like mobile or desktop applications. The browser is an amazing platform that allows you to be nimble
              for both your customers and your organization.`,
    component: FitProjectTypes,
  },
  {
    name: 'Product',
    colors: {
      wiperColors: [
        'bg-red-500',
        'bg-red-400',
        'bg-red-300',
        'bg-red-200',
      ],
      background: {
        from: 'from-red-500/50',
        to: 'to-red-800/80',
      },
      text: 'text-red-200',
      button: {
        bg: 'bg-red-500',
        hover: 'hover:bg-red-400',
      },
    },
    subtitle: 'How we dream it:',
    title: 'Working hand-in-hand to define the future of your organization',
    copy: `At LBM, we partner closely with our customers to define a product that aligns with their vision and goals, ensuring we answer two essential questions: "What should we build?" and "Why should we build it?" Through discovery sessions, collaborative planning, and a deep dive into business objectives, we clarify the product's purpose and potential impact, creating a roadmap that guides every stage of development.`,
    component: FitProduct,
  },
  {
    name: 'Engineering',
    colors: {
      wiperColors: [
        'bg-emerald-500',
        'bg-emerald-400',
        'bg-emerald-300',
        'bg-emerald-200',
      ],
      background: {
        from: 'from-emerald-500/50',
        to: 'to-emerald-800/80',
      },
      text: 'text-emerald-200',
      button: {
        bg: 'bg-emerald-500',
        hover: 'hover:bg-emerald-400',
      },
    },
    subtitle: 'How we build it:',
    title: 'Innovative engineering that stays nimble and delivers',
    copy: `Our engineering team combines a deep understanding of the vast array of web technologies with years of experience to bring concepts to life with precision and creativity. From robust functionality to the subtle flourishes, we design each product to be as delightful to use as it is powerful, ensuring an engaging experience for every end user.`,
    component: FitEngineering,
  },
  {
    name: 'Program',
    colors: {
      wiperColors: [
        'bg-blue-500',
        'bg-blue-400',
        'bg-blue-300',
        'bg-blue-200',
      ],
      background: {
        from: 'from-blue-500/50',
        to: 'to-blue-800/80',
      },
      text: 'text-blue-200',
      button: {
        bg: 'bg-blue-500',
        hover: 'hover:bg-blue-400',
      },
    },
    subtitle: 'How we ship it:',
    title: 'World-class program management keeps us honest',
    copy: `Our program management team ensures your project runs smoothly start to finish and beyond, coordinating timelines, resources, and communication to keep everything on track. Focused on efficiency and transparency, they bridge the gap between our team and clients, ensuring goals are met and expectations consistently exceeded.`,
    component: FitProgram,
  },
]

const currentSlide = computed(() => slides[slideIndex.value])

function goNextSlide() {
  if (slideIndex.value === slides.length - 1) {
    emit('close')
    return
  }
  slideIndex.value = slideIndex.value + 1
}

//  Transitions
function onBeforeSlideEnter(el: Element) {
  const hel = el as HTMLElement
  // move slide all the way off the screen to the right
  hel.style.transform = 'translateX(100%)'

  if (wiper.value) {
    wiper.value.style.transform = 'translateX(100%)'
    wiper.value.style.width = ''
  }
}
async function onSlideEnter(el: Element, done: () => void) {
  const hel = el as HTMLElement

  if (wiper.value) {
    animate(wiper.value, { transform: 'translateX(0%)' }, { duration: 0.5 })
    animate(wiper.value, { width: '0' }, { duration: 0.7, easing: 'ease-out' })
  }
  await animate(hel, { transform: 'translateX(0%)' }, { duration: 0.7, easing: 'ease-out' }).finished
  slideIndexLoaded.value = slideIndex.value
  done()
}
async function onSlideLeave(el: Element, done: () => void) {
  const hel = el as HTMLElement
  await animate(hel, { transform: 'translateX(-100%)' }, { duration: 0.5 }).finished
  done()
}
</script>

<template>
  <div class="fixed backdrop-blur-xl inset-0 z-50 opacity-0 ">
    <Transition
      :css="false"
      @before-enter="onBeforeSlideEnter"
      @enter="onSlideEnter"
      @leave="onSlideLeave"
    >
      <LbmFitGenericFit
        :key="currentSlide.name"
        :slide="currentSlide"
        :slide-index-loaded="slideIndexLoaded"
        class="absolute inset-0 overflow-y-scroll"
        @next="goNextSlide"
      />
    </Transition>

    <div ref="wiper" class="absolute top-0 left-0 bottom-0 w-full translate-x-full flex">
      <div class="w-32 h-screen" :class="[currentSlide.colors.wiperColors[0]]" />
      <div class="w-16 h-screen" :class="[currentSlide.colors.wiperColors[1]]" />
      <div class="w-8 h-screen" :class="[currentSlide.colors.wiperColors[2]]" />
      <div class="flex-1 h-screen" :class="[currentSlide.colors.wiperColors[3]]" />
    </div>

    <button class="absolute top-8 right-8 hover:scale-75 duration-300" @click="emit('close')">
      <Icon name="heroicons:x-circle" class="text-white w-12 h-12" />
    </button>
  </div>
</template>
