<script lang="ts" setup>

</script>

<template>
  <div class="w-full aspect-square space-y-3 border border-stone-500 rounded-xl overflow-hidden shadow-2xl shadow-white/40">
    <video
      src="/videos/retro-tunnel-2.mp4"
      class="w-full h-full object-cover"
      autoplay
      loop
      muted
    />
  </div>
</template>
