<script lang="ts" setup>
import { animate, timeline } from 'motion'
import { defineEmits, defineProps } from 'vue'

defineProps({
  slide: {
    subtitle: String,
    title: String,
    copy: String,
    colors: Array,
    component: Object,
  },
})

defineEmits(['next'])

const root = ref<HTMLElement | null>(null)

const animationProgress = ref(0)
function animateGrids() {
  animate((progress) => {
    animationProgress.value = progress
  }, { duration: 10 })
}

onMounted(() => {
  animateGrids()
})
</script>

<template>
  <div ref="root" class="bg-gradient-to-b" :class="[slide.colors.background.from, slide.colors.background.to]">
    <div class="container relative mx-auto min-h-screen flex justify-center items-center  text-orange-50">
      <div class="grid lg:grid-cols-12 lg:gap-12 px-12 lg:px-24 ">
        <div class="relative z-10 vertical-grid lg:col-span-6">
          <div class="mt-2 text-2xl lg:text-4xl font-lbm-serif italic tracking-tight horizontal-grid" :class="[slide.colors.text]">
            {{ slide.subtitle }}
          </div>
          <h2
            class="mt-2  font-lbm-serif italic lg:not-italic lg:uppercase tracking-tight text-white text-4xl sm:text-6xl horizontal-grid"
            v-html="slide.title "
          />
          <div class="mt-4 prose prose-invert prose-lg lg:prose-2xl font-lbm-serif">
            <p>
              {{ slide.copy }}
            </p>
          </div>
          <div class="relative z-10 mt-8">
            <button
              class="cursor-pointer flex items-center space-x-4 text-base md:text-xl font-bold text-white px-6 md:px-12 py-3 md:py-5 uppercase duration-300"
              :class="[slide.colors.button.bg, slide.colors.button.hover]"
              @click="$emit('next')"
            >
              <span>continue</span>
              <Icon name="heroicons:arrow-right-circle" class="w-6 md:w-8 h-6 md:h-8" />
            </button>
          </div>
        </div>

        <div class="relative z-0 hidden lg:col-span-6 lg:flex items-center max-w-[100vw] lg:max-w-[50vw]">
          <div class="w-full">
            <Component
              :is="slide.component"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.horizontal-grid {
  @apply relative;

  &::before {
    content: '';
    --middle-color-stop: v-bind(`${animationProgress * 100 * .2}%`);
    --end-color-stop: v-bind(`${animationProgress * 100}%`);
    @apply absolute inset-0 block bg-gradient-to-b from-current from-[1px] to-transparent to-[1px] pb-1 duration-500;
    background-size: 1em 1em;

    mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) var(--middle-color-stop), rgba(0,0,0,0) var(--end-color-stop));
  }
}

.vertical-grid {
  @apply relative;

  &::before {
    content: '';
    --middle-color-stop: v-bind(`${animationProgress * 100 * .5}%`);
    --end-color-stop: v-bind(`${animationProgress * 100}%`);
    @apply absolute inset-0 block bg-gradient-to-r from-current from-[1px] to-transparent to-[1px] pb-1 duration-500;
    background-size: 99%;

    mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) var(--middle-color-stop), rgba(0,0,0,0) var(--end-color-stop));
  }

  &> div, h2 {
    @apply pl-1 pr-3
  }
}
</style>
