<script lang="ts" setup>
import type { Swiper as SwiperType } from 'swiper'
import { timeline } from 'motion'
import { Swiper, SwiperSlide } from 'swiper/vue'

const desktopIcon = ref<HTMLElement | null>(null)

onMounted(() => {
  if (desktopIcon.value) {
    timeline([
      [desktopIcon.value, { opacity: [0, 1] }, { duration: 1 }],
    ])
  }
})

const swiper: Ref<SwiperType | null> = ref(null)
function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function prev() {
  swiper.value?.slidePrev()
}

function next() {
  swiper.value?.slideNext()
}
</script>

<template>
  <div class="overflow-hidden">
    <Swiper
      :slides-per-view="1"
      :centered-slides="true"
      :center-insufficient-slides="true"
      space-between="100vw"
      @swiper="onSwiper"
    >
      <SwiperSlide class="relative w-full">
        <div ref="desktopIcon" class="w-full aspect-square xl:aspect-video space-y-3 border border-stone-500 rounded-xl overflow-hidden p-8 opacity-0 bg-gradient-to-tl from-indigo-600 via-black via-70% to-black">
          <div class="text-base font-medium text-left">
            <div class="text-xl antialiased">
              Desktop Browsers
            </div>

            <div class="pt-2 text-sm text-stone-400 font-normal">
              We design and build for desktop browsers with meticulous attention to detail, ensuring a seamless, responsive experience that leverages the full potential of larger screens.
            </div>
          </div>

          <div class="translate-y-12 translate-x-12 w-full aspect-video border border-white/20 rounded-md overflow-hidden shadow-up shadow-indigo-600">
            <div class="grid grid-cols-12 h-full">
              <div class="col-span-4 bg-gradient-to-bl from-indigo-800 via-indigo-950 via-30% to-indigo-950 p-6">
                <Icon name="heroicons:x-mark" class="text-indigo-300 w-6 h-6" />

                <div class="font-lbm-block text-xs text-indigo-300 prose">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium sed culpa, saepe placeat cumqueutem illo et omnis fugit nisi.</p>
                </div>
              </div>
              <div class="col-span-8 h-full">
                <NuxtImg src="/imgs/wallpapers/1.jpg" class="object-cover w-full h-full" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="relative w-full">
        <div class="w-full aspect-square xl:aspect-video space-y-3 border border-stone-500 rounded-xl overflow-hidden p-8 bg-gradient-to-tr from-teal-600 via-black via-70% to-black ">
          <div class="text-base font-medium text-left">
            <div class="text-xl antialiased">
              Mobile Browsers
            </div>

            <div class="pt-2 text-sm text-stone-400 font-normal">
              We craft our solutions for mobile browsers with a focus on speed, usability, and intuitive design, ensuring a smooth, engaging experience on any device.
            </div>
          </div>

          <div class="translate-y-12 mx-auto w-[300px] aspect-[1/3] border border-white/20 rounded-3xl ring-4 ring-offset-0 ring-neutral-800 overflow-hidden drop-shadow-2xl shadow-teal-600">
            <div class="absolute top-0 left-0 right-0 flex justify-center">
              <div class="bg-black w-1/2 h-12 mx-auto -translate-y-4 rounded-3xl" />
            </div>
            <div class="w-full h-full">
              <div class="col-span-8 h-full">
                <NuxtImg src="/imgs/wallpapers/7.jpg" class="object-cover w-full h-full" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="relative">
        <div class="w-full aspect-square xl:aspect-video space-y-3 border border-stone-500 rounded-xl overflow-hidden p-8 bg-gradient-to-tl from-emerald-600 via-black via-70% to-black">
          <div class="text-base font-medium text-left">
            <div class="text-xl antialiased">
              Browser Applications
            </div>

            <div class="pt-2 text-sm text-stone-400 font-normal">
              Our team builds powerful, interactive applications directly in the browser, delivering functionality and performance that feel native, all within a seamless, accessible web experience.
            </div>
          </div>

          <div class="translate-y-12  w-full aspect-video border border-white/20 rounded-md overflow-hidden">
            <div class="grid grid-cols-12 h-full">
              <div class="col-span-6 h-full">
                <NuxtImg src="/imgs/wallpapers/10.jpg" class="object-cover w-full h-full" />
              </div>
              <div class="col-span-6 bg-gradient-to-bl from-emerald-800 via-emerald-950 via-30% to-emerald-950 p-6 grid grid-cols-2 gap-4">
                <div v-for="n in 6" :key="n" class="font-lbm-block text-xs text-emerald-300 prose">
                  <div class="h-12 rounded border border-emerald-300" />
                  <div class="text-center">
                    Lorem.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="relative">
        <div class="w-full aspect-square xl:aspect-video space-y-3 border border-stone-500 rounded-xl overflow-hidden p-8 bg-gradient-to-t from-amber-600 via-black via-70% to-black ">
          <div class="text-base font-medium text-left">
            <div class="text-xl antialiased">
              Marketing Efforts
            </div>

            <div class="pt-2 text-sm text-stone-400 font-normal">
              We create robust marketing and e-commerce solutions that captivate audiences, drive engagement, and streamline the customer journey, ensuring every interaction supports your brand’s growth and conversion goals.
            </div>
          </div>

          <div class="translate-y-12 translate-x-12 w-full aspect-video border border-white/20 rounded-md overflow-hidden">
            <div class="bg-gradient-to-bl from-amber-800 via-amber-950 via-30% to-amber-950">
              <NuxtImg src="/imgs/wallpapers/11.jpg" class="w-full" />
              <div class="font-lbm-block text-xs text-amber-300 prose">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                <p>laborum ut ipsum error voluptate ipsa iste, doloremque autem illo et omnis fugit nisi.</p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="flex justify-between p-8">
      <button class="uppercase text-sm font-lbm-sans" aria-label="Previous" @click="prev">
        <Icon name="heroicons:chevron-left" class="w-8 h-8" /> Previous
      </button>
      <button class="uppercase text-sm font-lbm-sans" aria-label="Next" @click="next">
        Next <Icon name="heroicons:chevron-right" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shadow-up {
  box-shadow: 0 -25px 50px 15px rgba(79, 70, 229, 0.3);
}
</style>

<style>
.swiper-wrapper {
  @apply flex;
}

.swiper-slide {
  @apply flex-none;
}
</style>
